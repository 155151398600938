.about-page-root {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.about-page-container {
  justify-content: center;
}

.about-page-sections {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1000px;
  justify-content: center;
  margin-top: -100px;
}
.about-page-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .about-page-container {
    width: 400px;
  }
  .about-page-sections {
    width: 400px;
  }
  .cv-img {
    width: 100%;
  }
}
