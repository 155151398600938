.three-section-root {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.three-section-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  padding-top: 1cm;
  padding-bottom: 1cm;
  justify-content: center;
}
.under-title {
  width: 200px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: xx-large;
}

.three-section-img {
  height: 200px;
  width: 200px;
  overflow: hidden;
  object-fit: cover;
}

.three-section {
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
}
