.only-text-root {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding-bottom: 0.5cm;
}

.only-text-container {
  text-align: center;
  width: 800px;
}

.only-text-text {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-size: large;
}
