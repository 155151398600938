.photo-image-root {
  background-color: black;
}

.photo-image-img {
  height: auto;
  max-width: 900px;
  max-height: 700px;
  padding-top: 50px;
}
@media only screen and (max-width: 600px) {
  .photo-image-img {
    width: 100%;
    margin: 0px;
  }
}
