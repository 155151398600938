.slideshow-root {
  background-color: white;
  padding-bottom: 5px;
}

.carousel-nexts {
  fill: black;
  color: black;
  stroke: black;
}

.carousel-img {
  height: auto;
  max-width: 800px;
  max-height: 600px;
  overflow: hidden;
  object-fit: cover;
}
@media only screen and (max-width: 600px) {
  .carousel-img {
    width: 100%;
  }
}
