.thumbnail-root {
  overflow: auto;
}

.thumbnail-container {
  height: 700px;
}
@media only screen and (max-width: 600px) {
  .thumbnail-container {
    height: 250px;
  }
}
.inside-parallax {
  background-color: white;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: x-large;
  color: black;
  border-radius: 10px;
}
