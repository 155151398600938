.embed-root {
  background-color: white;
  padding-bottom: 5px;
}

.youtube {
    width: 800px;
    height: 450px;
}

@media only screen and (max-width: 600px) {
  .youtube {
    width: 375px;
    height: 210px;
  }
}
