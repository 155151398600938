.root {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  height: 92vh;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scroll {
  position: relative;
  color: white;
  font-weight: 500;
  font-size: xx-large;
}

.name {
  position: relative;
  color: white;
  font-weight: 500;
  font-size: large;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
}

.img {
  position: relative;
  height: 100px;
  width: 100px;
}
