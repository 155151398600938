.only-title-root {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding-top: 0.5cm;
}

.only-title-container {
  text-align: center;
  width: 800px;
}

.only-title-text {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: xx-large;
}
