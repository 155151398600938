.about-root {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.about-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  padding-left: 2cm;
  padding-right: 2cm;
  padding-top: 3cm;
  padding-bottom: 3cm;
  justify-content: center;
}

.about-link {
  text-decoration: none;
  color: black;
}

.about-text-section {
  width: 400px;
}

.about-image-section {
  width: 400px;
}

.about-title {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: xx-large;
}

.about-description {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-size: large;
}

.about-img {
  height: 400px;
}
