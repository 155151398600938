.contact-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.contact-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 80%;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: x-large;
}
.contact-title {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: xx-large;
}
.clickable {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-size: x-large;
  text-transform: lowercase;
  color: black;
}
.contact-images {
  padding: 50px;
}
.contact-img {
  max-height: 100px;
  max-width: 100px;
}
.clickable {
  padding: 50px;
}

.contact-img:hover {
  opacity: 0.6;
}
