.image-grid-root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 600px;
}

.grid-img {
  padding: 15px;
  display: flex;
  height: 150px;
  width: 150px;
  overflow: hidden;
  object-fit: cover;
}

@media only screen and (max-width: 800px) {
  .image-grid-root {
    width: 400px;
  }
}
