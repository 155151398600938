.header-root {
  height: 700px;
}

.header-title {
  z-index: 2;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: xx-large;
}

.header-description {
  z-index: 2;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: large;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.header-text-container {
  background: white;
  padding: 30px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-width: 50%;
}
@media screen and (max-width: 600px) {
  .header-text-container {
    max-width: 90%;
  }
}
