.image-section-root {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.image-section-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  padding-left: 2cm;
  padding-right: 2cm;
  padding-top: 1cm;
  padding-bottom: 1cm;
  justify-content: center;
}

.image-section-text-section {
  display: inline-block;
  width: 400px;
}

.image-section-image-section {
  width: 400px;
}

.image-section-title {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: xx-large;
}

.image-section-description {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-size: large;
  padding-top: 1cm;
}

.image-section-img {
  height: 400px;
  width: 300px;
  overflow: hidden;
  object-fit: cover;
}
