#root .App {
  background-color: #557d82;
  min-height: 100vh;
  text-align: center;
  overflow-x: hidden;
}
.navigation {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px;
}

.navigation-sub {
  display: flex;
  flex-direction: row;
  text-transform: capitalize;
  font-size: x-large;
  padding-right: 0.5cm;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  padding-top: 3mm;
}

.item {
  color: white;
  text-decoration: none;
  padding-right: 10px;
  padding-left: 10px;
}

@media only screen and (max-width: 600px) {
  .navigation {
    justify-content: center;
  }
  .navigation-sub {
    padding-right: 0cm;
    font-size: medium;
  }
}

.logo-image {
  width: 50px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.copyright {
  position: relative;
  width: 100%;
  color: #fff;
  line-height: 40px;
  font-size: 0.7em;
  text-align: center;
  bottom: 0;
}
